import React from 'react'
import styled from 'styled-components'
import clsx from 'clsx'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 5,
    height: 48,
    padding: '1.5rem 0',
    width: '100%',
    textTransform: 'none',
    boxShadow: '0 0 0 2px transparent',
  },
  primaryButton: {
    backgroundColor: theme.buttons.primary.background,
    color: theme.buttons.primary.label,
    '&$disabled': {
      backgroundColor: theme.buttons.primary.background,
    },
    '&:hover': {
      backgroundColor: theme.buttons.primary.background,
    },
    '&:active': {
      boxShadow: `0 0 0 5px ${theme.colors.stroke}`,
      transition: 'box-shadow 0.1s ease-out',
    },
  },
  secondaryButton: {
    backgroundColor: theme.buttons.secondary.background,
    color: theme.buttons.secondary.label,
    border: `1px solid ${theme.buttons.secondary.label}`,
    '&$disabled': {
      backgroundColor: theme.buttons.secondary.background,
    },
    '&:hover': {
      backgroundColor: theme.buttons.secondary.background,
    },
  },
  cancelButton: {
    backgroundColor: 'transparent',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}))

const PrimaryButton = ({children, className, ...props}) => {
  const classes = useStyles()
  return (
    <Button
      variant="contained"
      disableRipple
      className={clsx(classes.button, classes.primaryButton, className)}
      {...props}>
      {children}
    </Button>
  )
}
const SecondaryButton = ({children, className, ...props}) => {
  const classes = useStyles()
  return (
    <Button
      className={clsx(classes.button, classes.secondaryButton, className)}
      variant="contained"
      {...props}
      disableRipple>
      {children}
    </Button>
  )
}

const UnstyledButton = (props) => (
  <UnstyledButtonComp {...props}></UnstyledButtonComp>
)

const UnstyledButtonComp = styled.button`
  font-size: 1.4rem;
  border: ${(props) => props.border || 'none'};
  background: transparent;
  color: ${({theme, color}) => color || theme.colors.primaryLight};
  display: flex;
  align-items: center;
  font-family: ${({theme}) => theme.fonts.primary};
  font-weight: 500;
`

const CancelButton = (props) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <UnstyledButton
      {...props}
      id="cancel-delivery-button"
      color={theme.colors.error}
      className={classes.cancelButton}>
      <CloseIcon />
      Cancel
    </UnstyledButton>
  )
}

export {PrimaryButton, SecondaryButton, UnstyledButton, CancelButton}
