import React, {useEffect, useCallback} from 'react'
import {connect} from 'react-redux'
import {Route} from 'react-router-dom'
import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react'

import Navbar from '../../containers/Navbar'
import ToastMessage from '../ToastMessage'

import {updateUser, saveToken} from '../../redux/actions'

function PrivateRoute({
  component,
  dispatchUpdateUser,
  dispatchSaveToken,
  ...rest
}) {
  const {isLoading, isAuthenticated, user, getIdTokenClaims} = useAuth0()

  const getToken = useCallback(async () => {
    try {
      const accessToken = await getIdTokenClaims()
      return accessToken.__raw
    } catch (err) {
      console.log('err', err)
    }
  }, [getIdTokenClaims])

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getToken().then((token) => {
        dispatchUpdateUser(user)
        dispatchSaveToken(token)
      })
    }
  }, [
    dispatchSaveToken,
    dispatchUpdateUser,
    getToken,
    isAuthenticated,
    isLoading,
    user,
  ])

  return isLoading ? (
    'Loading...'
  ) : (
    <>
      <Navbar />
      <Route
        component={withAuthenticationRequired(component, {
          onRedirecting: () => 'isLoading',
        })}
        {...rest}
      />
      <ToastMessage />
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateUser: (userInfo) => dispatch(updateUser(userInfo)),
  dispatchSaveToken: (token) => dispatch(saveToken(token)),
})

export default connect(null, mapDispatchToProps)(PrivateRoute)
