const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const formatDate = (date = new Date()) => {
  const dateObj = new Date(date)
  const monthIndex = dateObj.getMonth()
  const day = dateObj.getDate()
  const year = dateObj.getFullYear()

  return `${MONTHS[monthIndex]} ${day}, ${year}`
}

export const apiFormatDate = (date = new Date()) => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear()

  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`
}

export const generalFormatDate = (date = '2020-08-28') => {
  const regx = /[0-9]{4}-[0-9]{2}-[0-9]{2}/

  return `${date}`.match(regx) ? simpleDateFormater(date) : new Date(date)
}

export const isDate = (date) => {
  const regx = /[a-zA-Z]{3} [a-zA-Z]{3} [0-9]{2} [0-9]{4}/g

  return `${date}`.match(regx)
}

export const simpleDateFormater = (date = '') => {
  const splitedDate = date.split('-')
  const year = splitedDate[0]
  const month = splitedDate[1] - 1
  const day = splitedDate[2]

  const formatedDate = new Date()
  formatedDate.setFullYear(year)
  formatedDate.setMonth(month, day)
  return formatedDate
}

export const isDateProp = (datesProps = [], dateProp) =>
  datesProps.includes(dateProp)

export function getInitialStartAndEndDate() {
  let todayDate = new Date()
  const SEVEN_DAYS_MILLISECONDS = 604800000
  const TWENTY_ONE_DAYS_MILLISECONDS = 1814400000
  const sevenDaysInFuture = new Date(todayDate.getTime() + SEVEN_DAYS_MILLISECONDS)
  const threeWeeksInPast = new Date(todayDate.getTime() - TWENTY_ONE_DAYS_MILLISECONDS)
  return {sevenDaysInFuture, threeWeeksInPast}
}