import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter as Router} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'
import {Auth0Provider} from '@auth0/auth0-react'

import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import {StylesProvider} from '@material-ui/core/styles'

import 'fontsource-roboto'

import globalStyles from './theme'
import {store, persistor} from './redux/store'
import * as serviceWorker from './serviceWorker'

import App from './App'
import './index.css'

const overrides = {
  MuiButton: {},
}

const typography = {
  htmlFontSize: 10,
  fontFamily: 'Roboto, sans-serif',
  variantMapping: {
    h1: 'h2',
    h2: 'h2',
    h3: 'h2',
    h4: 'h2',
    h5: 'h2',
    h6: 'h2',
    subtitle1: 'h2',
    subtitle2: 'h2',
    body1: 'p',
    body2: 'p',
  },
  h1: {
    fontSize: '2.4rem',
    fontWeight: 'bold',
  },
  h2: {fontsize: '2rem'},
  subtitle1: {
    fontSize: '1.6rem',
  },
  body1: {
    fontSize: '1.4rem',
  },
  body2: {
    fontSize: '1.4rem',
    fontWeight: 500,
  },
}

const palette = {
  primary: {
    main: globalStyles.colors.primary,
  },
}

const theme = createMuiTheme({overrides, typography, palette, ...globalStyles})
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <CssBaseline>
                <Router>
                  <Auth0Provider
                    domain={process.env.REACT_APP_AUTH0_DOMAIN}
                    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                    redirectUri={`${window.location.origin}/dashboard`}
                    audience={process.env.REACT_APP_AUTHO_AUDIENCE_URL}>
                    <App />
                  </Auth0Provider>
                </Router>
              </CssBaseline>
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

serviceWorker.unregister()
