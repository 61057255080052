import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

import {UnstyledButton} from '../../components/Button'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({theme}) => theme.colors.background};
  padding: 2.8rem 4.7rem;
`
const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const DeliveryDetails = styled.div`
  width: 100%;
  min-height: 8rem;
  display: flex;
  justify-content: start;
`
const LabelTextCombination = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 2rem;
`

const CancelDeliveryButton = styled(UnstyledButton)`
  justify-self: end;
  color: ${({theme}) => theme.colors.error};
`

const HeaderContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

const HeaderTypography = styled(Typography)`
  text-align: center;
`

export {
  Container,
  TopContainer,
  DeliveryDetails,
  LabelTextCombination,
  CancelDeliveryButton,
  HeaderTypography,
  HeaderContainer,
}
