import React, {useEffect, useState} from 'react'
import {Route, Switch, useRouteMatch} from 'react-router-dom'
import styled from 'styled-components'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import {connect} from 'react-redux'
import get from 'lodash.get'

import Delivery from '../Delivery'
import AddNewPrescription from '../AddNewPrescription'
import Home from '../Home'

const Container = styled.div`
  width: 100%;
`

const Dashboard = ({stripeAccountId}) => {
  let match = useRouteMatch()
  const [stripe, setStripe] = useState()
  useEffect(() => {
    const stripe = loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
      stripeAccount: stripeAccountId,
    })
    setStripe(stripe)
  }, [stripeAccountId])

  return (
    <Container id="dashboard">
      <Switch>
        <Route
          path={`${match.url}/delivery/:deliveryId/add-new-prescription/:orderId`}
          render={() =>
            stripe ? (
              <Elements stripe={stripe}>
                <AddNewPrescription rootPath={match.url} />{' '}
              </Elements>
            ) : (
              ''
            )
          }
        />
        <Route
          path={`${match.url}/delivery/:deliveryId/add-new-prescription`}
          render={() =>
            stripe ? (
              <Elements stripe={stripe}>
                <AddNewPrescription rootPath={match.url} />
              </Elements>
            ) : (
              ''
            )
          }
        />
        <Route
          path={`${match.url}/delivery/:deliveryId`}
          render={() => <Delivery rootPath={match.url} />}
        />
        <Route path={match.url} render={() => <Home rootPath={match.url} />} />
      </Switch>
    </Container>
  )
}
const mapStateToProps = ({user = {}}) => {
  return {
    stripeAccountId: get(user, 'store.stripeAccountId'),
  }
}
export default connect(mapStateToProps)(Dashboard)
