import {
  DOWNLOAD_CSV,
} from '../actions/types'

const downloadCsv = ({startDate, endDate}) => {
  return {
    type: DOWNLOAD_CSV,
    payload: {
      startDate,
      endDate
    },
  }
}

export {downloadCsv}
