import styled from 'styled-components'

export const Form = styled.form`
display: flex;
flex-direction: column;
width: 100%;
padding: 4rem 14rem;
& > *:not(:last-child) {
  margin-bottom: 1.6rem;
`

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const BarcodeList = styled.ol`
  margin: 0;
  padding: 0;
  list-style-type: none;
  
  & > li {
    position: relative;
    counter-increment: customlistcounter;
  }
  
  & > li:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  & > li:before {
    position: absolute;
    margin-top: 4.5rem;
    color: ${({theme}) => theme.colors.label};
    font-weight: 500;
    font-size: 1.4rem;
    position: absolute;
    left: -2.5rem;

    content: counter(customlistcounter) " ";
  }
  
  &:first-child {
    counter-reset: customlistcounter;
  }
`
