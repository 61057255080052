module.exports = {
  fonts: {
    primary: 'Roboto',
    secondary: '',
  },
  colors: {
    primary: '#006BD6',
    primaryLight: '#3b83ee',
    primary3: '#96B9DF',
    secondary: '#FFB400',
    error: '#E20F00 ',
    errorDark: '#b00c00',
    success: '#1FBE66',
    textPrimary: '#221E3A',
    textSecondary: '#4D4961',
    lightText: '#FFFFFF',
    label: '#706E84',
    background: '#F5F6FA',
    secondaryBackground: '#FDFDFD',
    darkBackground: '#4D4961',
    iconBackground: '#C4D5EE',
    iconfill: '#3B83EE',
    inactive: '',
    stroke: '#C4D5EE',
  },
  buttons: {
    primary: {
      background: '#006BD6',
      label: '#FDFDFD',
    },
    secondary: {
      label: '#706E84',
      background: '#FDFDFD',
    },
  },
  card: {
    background: '#FFFFFF',
    shadow: 'rgba(0, 0, 0, 0.07)',
  },
  screenSizes: {
    xs: 'min-width: 425px',
    sm: 'min-width: 768px',
    md: 'min-width: 1024px',
    lg: 'min-width: 1440px',
  },
  status: {
    pending: '#FFB400',
    success: '#1FBE66',
    error: '#E20F00',
  }
}
