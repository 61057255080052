import React, {useEffect, useRef, useState} from 'react'
import {
  CardNumberElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'

import {connect} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import get from 'lodash.get'
import {object, string, array, addMethod} from 'yup'
import {useForm, Controller, useFieldArray} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'

import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'

import PrescriptionForm from '../PrescriptionForm'

import {
  CancelPrescriptionModal,
  CancelPrescriptionDraftModal,
  ConfirmPrescriptionModal,
} from '../../components/Modal'
import PaymentForm from '../PaymentForm'
import ShippingForm from '../ShippingForm'

import {Modal} from '../../components/Modal'
import Divider from '../../components/FormDivider'
import Radio from '../../components/Radio'
import {
  PrimaryButton as SaveButton,
  SecondaryButton as AddPrescriptionButton,
  CancelButton,
} from '../../components/Button'
import Loading from '../../components/Loading'

import {
  clearPaymentMethod,
  getPayment,
  getOrder,
  addOrder,
  updateOrder,
  paymentMethodSuccess,
} from '../../redux/actions'

import {Form, TopContainer, BarcodeList} from './styles'
import BackButton from '../../components/BackButton'
import { START_LOADING, STOP_LOADING } from '../../redux/actions/types'

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    marginBottom: '1.2rem',
  },
  saveButton: {
    width: '21.6rem',
    margin: '15px 0',
  },
  topContainer: {
    textAlign: 'center',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  button: {
    width: '14rem',
    margin: '3rem auto',
  },
}))

const getOrderFromDelivery = (delivery, orderId) =>
  get(delivery, 'orders', []).reduce(
    (acc, order) => (order.orderId === parseInt(orderId) ? order : acc),
    {},
  )

addMethod(array, 'unique', function (message, path) {
  return this.test('unique', message, function (list) {
    const mapper = (x) => get(x, path)
    const set = [...new Set(list.map(mapper))]
    const isUnique = list.length === set.length
    if (isUnique) {
      return true
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i])
    return this.createError({path: `${this.path}[${idx}][${path}]`})
  })
})
const prescriptionValidationSchema = object().shape({
  barCodeNumber: string().required('Barcode required'),
  price: string().required('Price required'),
})

// TODO: modify form to take in prescriptionValidationSchema
const newPrescriptionValidationSchema = object().shape({
  fullName: string().required('Required'),
  shippingAddress: string()
    .required('Required')
    .min(5, 'Address value is too short')
    .max(200, 'Address value is too long'),
  phoneNumber: string().required('Required'),
  prescriptions: array()
    .of(prescriptionValidationSchema)
    .unique('Barcode is duplicated', 'barCodeNumber'),
  paymentMethod: string().required('Required'),
  cardHolderName: string().when('paymentMethod', {
    is: (val) => val === 'credit',
    then: string().required('Required'),
  }),
})

const AddNewPrescription = ({
  clearPayment,
  getPayment,
  paymentDetails,
  setPaymentMethod,
  addOrder,
  updateOrder,
  newDeliveryData,
  selectedDelivery,
  loading,
  startLoading,
  stopLoading
}) => {
  const classes = useStyles()
  const history = useHistory()
  const {deliveryId, orderId} = useParams()
  const formRef = useRef()
  const [autofocusIndex, setAutofocusIndex] = useState(-1)

  const order = getOrderFromDelivery(selectedDelivery, orderId)

  const scannedPrescription = {
    barCodeNumber: '',
    price: get(newDeliveryData, 'price', ''),
    refrigiated: false,
    isDeleted: false,
  }

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      prescriptions: [scannedPrescription],
      paymentMethod: 'none',
      fullName: get(newDeliveryData, 'customerName', ''),
      phoneNumber: get(newDeliveryData, 'customerPhone', ''),
      shippingAddress: get(newDeliveryData, 'customerAddress', ''),
      deliveryNote: '',
      cardHolderName: '',
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
    },
    resolver: yupResolver(newPrescriptionValidationSchema),
  })

  const [modal, setModal] = React.useState({
    isOpen: false,
    modalContent: '',
  })

  const {fields, append, remove, insert} = useFieldArray({
    control,
    name: 'prescriptions',
  })

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    if (orderId) {
      const {
        paymentType,
        customerName,
        customerPhone,
        customerAddress,
        orderNote,
        detail,
      } = getOrderFromDelivery(selectedDelivery, orderId)

      let payment = ''
      if(paymentType === 'CARD'){
        payment = 'credit'
      } else if(paymentType === 'CASH'){
        payment = 'cash'
      } else {
        payment = 'none'
      }

      const updatedForm = {
        prescriptions: detail.map(
          ({barcode, price, isRefrigerated, orderDetailId}) => ({
            barCodeNumber: barcode,
            price,
            refrigiated: isRefrigerated,
            orderDetailId,
            isDeleted: false,
          }),
        ),
        paymentMethod: payment,
        fullName: customerName,
        phoneNumber: customerPhone,
        shippingAddress: customerAddress,
        deliveryNote: orderNote,
        cardHolderName: get(paymentDetails, 'cardholderName', ''),
      }
      reset(updatedForm)
    }
  }, [orderId, reset, selectedDelivery, paymentDetails])

  useEffect(() => {
    const { orderId } = order
    if (orderId) {
      const { payment, stripePaymentIntentId } = order
      if(stripePaymentIntentId && !payment) {
        getPayment({orderId, stripePaymentIntentId})
        return clearPayment
      }
    }
  }, [order, clearPayment, getPayment])

  const handlePriceChange = () => {
    setValue('cardHolderName', '')
    setValue('cardNumber', '')
    setValue('cardExpiry', '')
    setValue('cardCvc', '')
  }

  const handleSubmitPrescription = (form, successCb) => {
    const { stripePaymentIntentId } = getOrderFromDelivery(selectedDelivery, orderId)
    const order = {
      ...form,
      stripePaymentIntentId
    }
    addOrder({
      order,
      deliveryId,
      history,
      successCb,
    })
    handleModalClose()
  }

  const updatePrescription = (form, successCb) => {
    const {prescriptions} = form
    const newPrescriptions = prescriptions.map((eachPrep) =>
      eachPrep.isDeleted
        ? {orderDetailId: eachPrep.orderDetailId, isDeleted: eachPrep.isDeleted}
        : eachPrep,
    )
    const updatedOrder = {...form, prescriptions: newPrescriptions}
    updateOrder({orderId, updatedOrder, history, deliveryId, successCb})
    handleModalClose()
  }

  const handleModalOpen = ({modalContent}) => {
    setModal({
      isOpen: true,
      modalContent,
    })
  }
  const handleModalClose = () => {
    setModal({
      isOpen: false,
      modalContent: '',
    })
  }

  const handleAddNewPrescription = () => {
    const prescriptionValues = {
      barCodeNumber: '',
      price: '',
      refrigiated: false,
      isDeleted: false,
    }

    append(prescriptionValues)
    setAutofocusIndex(fields.length)
  }

  const openConfirmPrescriptionModal = () => {
    handleModalOpen({
      modalContent: 'ConfirmPrescriptionModal',
    })
  }

  const handleCancelPrescription = () => {
    handleModalOpen({
      modalContent: orderId
        ? 'CancelPrescriptionModal'
        : 'CancelPrescriptionDraftModal',
    })
  }

  const removeUnsavedPrescriptions = (position) => {
    remove(position)
  }
  const displayModal = (modalType, values) => {
    switch (modalType) {
      case 'ConfirmPrescriptionModal':
        return (
          <ConfirmPrescriptionModal
            handleClose={handleModalClose}
            updatePrescription={updatePrescription}
            handleSubmitPrescription={handleSubmitPrescription}
            orderId={orderId}
            getValues={getValues}
          />
        )
      case 'CancelPrescriptionModal':
        return (
          <CancelPrescriptionModal
            handleClose={handleModalClose}
            redirectUrl={`/dashboard/delivery/${deliveryId}`}
          />
        )
      case 'CancelPrescriptionDraftModal':
        return (
          <CancelPrescriptionDraftModal
            handleClose={handleModalClose}
            redirectUrl={`/dashboard/delivery/${deliveryId}`}
          />
        )
      default:
        break
    }
  }

  async function checkStripeValidity() {
    const cardNumberElement = elements.getElement(CardNumberElement)

    if (cardNumberElement) {
      startLoading()
      const {paymentMethod, error} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name: watch('cardHolderName'),
        }
      })
      stopLoading()
      if (error) {
        return
      } else {
        setPaymentMethod(paymentMethod.id)
        openConfirmPrescriptionModal()
      }
    } else {
      openConfirmPrescriptionModal()
    }
  }

  function submitForm(data) {
    const {paymentMethod} = data
    paymentMethod === 'credit'
      ? checkStripeValidity()
      : openConfirmPrescriptionModal()
  }

  async function handleSavePrescription() {
    // We need to remove the last item of the prescriptions array if it is programmically added after
    // last scan. However we need to allow validation to happen if the last item has been partially
    // filled in to allow for use case where User wants to keep the last item. Dispatch for submit event
    // happens after checks for last item empty value.
    const {prescriptions} = getValues()
    const lastIndex = prescriptions.length - 1
    const lastPrescription = prescriptions[lastIndex]
    if (lastIndex > 0) {
      if (
        lastPrescription.barCodeNumber === '' &&
        lastPrescription.price === ''
      ) {
        await remove(lastIndex)
      }
      formRef.current.dispatchEvent(new Event('submit'))
    } else {
      formRef.current.dispatchEvent(new Event('submit'))
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(submitForm)} ref={formRef}>
        <TopContainer className={classes.topContainer}>
          <BackButton click={handleCancelPrescription} />
          <Typography variant="h1" className={classes.header}>
            {orderId ? `Edit Order` : `New Order`}
          </Typography>
          {!orderId && (
            <CancelButton
              type="button"
              onClick={handleCancelPrescription}></CancelButton>
          )}
        </TopContainer>
        <BarcodeList>
          {fields.map((prescription, position) => {
            const shouldFocus = position === autofocusIndex
            const style = prescription.isDeleted ? {display: 'none'} : {}
            return (
              <li style={style}>
                <PrescriptionForm
                  key={prescription.id}
                  position={position}
                  autoFocus={shouldFocus}
                  prescription={prescription}
                  errors={errors}
                  control={control}
                  setValue={setValue}
                  insert={insert}
                  remove={remove}
                  getValues={getValues}
                  removeUnsavedPrescriptions={removeUnsavedPrescriptions}
                  addNewPrescription={handleAddNewPrescription}
                  onPriceChange={handlePriceChange}
                />
              </li>
            )
          })}
        </BarcodeList>
        <AddPrescriptionButton
          id="add-new-prescription-button"
          onClick={handleAddNewPrescription}>
          Add New Prescription
        </AddPrescriptionButton>
        <ShippingForm
          control={control}
          register={register}
          errors={errors}
          watch={watch}
        />
        <Divider />
        <Typography variant="h1" className={classes.header}>
          Payment
        </Typography>
        <Controller
          as={
            <RadioGroup row aria-label="payment method" type="string">
              <Radio id="none-radio-button" value="none" label="None" />
              <Radio
                id="credit-radio-button"
                value="credit"
                label="Credit Card"
              />
              <Radio id="cash-radio-button" value="cash" label="Cash" />
            </RadioGroup>
          }
          name="paymentMethod"
          control={control}
          defaultValue="cash"
        />
        {(
          <PaymentForm
            paymentMethod={watch('paymentMethod')}
            paymentDetails={paymentDetails}
            register={register}
            errors={errors}
            getValues={getValues}
            elements={elements}
            stripe={stripe}
            control={control}
          />
        )}
        <SaveButton
          id="save-prescription-button"
          className={classes.button}
          onClick={handleSavePrescription}>
          Save
        </SaveButton>
      </Form>

      <Modal isOpen={modal.isOpen} handleClose={handleModalClose}>
        {displayModal(modal.modalContent)}
      </Modal>
      <Loading open={loading} />
    </>
  )
}

const mapStateToProps = ({
  deliveries: {newDeliveryData = {}, selectedDelivery},
  payment: {details: paymentDetails},
  app: {loading},
}) => {
  return {
    selectedDelivery,
    newDeliveryData,
    paymentDetails,
    loading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearPayment: (payload) => dispatch(clearPaymentMethod(payload)),
  getPayment: (payload) => dispatch(getPayment(payload)),
  addOrder: (payload) => dispatch(addOrder(payload)),
  setPaymentMethod: (payload) => dispatch(paymentMethodSuccess(payload)),
  getOrder: (orderId) => dispatch(getOrder({orderId})),
  updateOrder: (payload) => dispatch(updateOrder(payload)),
  startLoading: () => dispatch({type: START_LOADING}),
  stopLoading: () => dispatch({type: STOP_LOADING}),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPrescription)
