import React, {useRef} from 'react'
import styled from 'styled-components'
import TextField from '../../components/Textfield'
import InputMask from 'react-input-mask'
import {Controller} from 'react-hook-form'
import get from 'lodash.get'

import Error from '../../components/Error'

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  & > *:first-child {
    margin-right: 2rem;
  }
`

const ShippingForm = ({control, register, errors, watch}) => {
  const fullNameInputRef = useRef()
  const phoneInputRef = useRef()
  const addressInputRef = useRef()
  return (
    <>
      <Row>
        <Controller
          name="fullName"
          id="full-name"
          control={control}
          onFocus={() => {
            fullNameInputRef.current.focus()
          }}
          as={
            <TextField
              label="Full Name"
              helperText={get(errors, 'fullName.message', '')}
              inputRef={fullNameInputRef}
              error={!!errors.fullName}
              FormHelperTextProps={{
                component: (props) => <Error name="fullName" {...props} />,
              }}
            />
          }
        />

        <Controller
          name="phoneNumber"
          control={control}
          rules={{required: 'Required'}}
          onFocus={() => {
            phoneInputRef.current.focus()
          }}
          as={
            <InputMask maskChar="" mask="999-999-9999">
              {(inputProps) => (
                <TextField
                  id="phone-number"
                  type="tel"
                  label="Phone"
                  name="phoneNumber"
                  inputRef={phoneInputRef}
                  error={!!errors.phoneNumber}
                  helperText={get(errors, 'phoneNumber.message', '')}
                  FormHelperTextProps={{
                    component: (props) => (
                      <Error name="phoneNumber" {...props} />
                    ),
                  }}
                />
              )}
            </InputMask>
          }
        />
      </Row>
      <Controller
        name="shippingAddress"
        id="shipping-address"
        control={control}
        onFocus={() => {
          addressInputRef.current.focus()
        }}
        as={
          <TextField
            type="text"
            label="Shipping Address"
            inputRef={addressInputRef}
            error={!!errors.shippingAddress}
            helperText={get(errors, 'shippingAddress.message', '')}
            FormHelperTextProps={{
              component: (props) => <Error name="shippingAddress" {...props} />,
            }}
          />
        }
      />

      <TextField
        type="text"
        label="Delivery Note (optional)"
        name="deliveryNote"
        id="delivery-note"
        inputRef={register}
      />
    </>
  )
}
export default ShippingForm
