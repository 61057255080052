import styled from 'styled-components'

const StatusIcon = styled.div`
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({statusState = '', theme}) =>
    theme.status[statusState] || theme.status.pending};
  margin: 0 10px 0 0;
  display: inline-block;
`

export default StatusIcon
