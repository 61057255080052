import {
  GET_DELIVERIES,
  GET_DELIVERIES_SUCCESS,
  GET_DELIVERIES_FAIL,
  GET_DELIVERY,
  GET_DELIVERY_SUCCESS,
  CLEAR_CURRENT_DELIVERY,
  ADD_DELIVERY,
  ADD_DELIVERY_SUCCESS,
  CANCEL_DELIVERY,
  CANCEL_DELIVERY_SUCCESS,
  UPDATE_DELIVERY,
  SET_ORDERS,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  ADD_ORDER,
  ADD_ORDER_SUCCESS,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  DELETE_ORDER,
} from './types'
import {getInitialStartAndEndDate} from '../../utils/dates'

const getDeliveries = (dates) => {
  const {sevenDaysInFuture, threeWeeksInPast} = getInitialStartAndEndDate()
  return {
    type: GET_DELIVERIES,
    payload: dates
      ? {dates}
      : {dates: {startDate: threeWeeksInPast, endDate: sevenDaysInFuture}},
  }
}

const getDeliveriesSuccess = (deliveries) => {
  return {
    type: GET_DELIVERIES_SUCCESS,
    deliveries,
  }
}

const getDelivery = (deliveryId) => {
  return {type: GET_DELIVERY, payload: {deliveryId}}
}
const getDeliverySuccess = (delivery) => {
  return {type: GET_DELIVERY_SUCCESS, delivery}
}
const getDeliveriesFail = (error) => {
  return {type: GET_DELIVERIES_FAIL, error}
}

const clearCurrentDelivery = () => {
  return { type: CLEAR_CURRENT_DELIVERY }
}

const addDelivery = (history) => {
  return {
    type: ADD_DELIVERY,
    payload: {history},
  }
}
const addDeliverySuccess = (delivery) => {
  return {
    type: ADD_DELIVERY_SUCCESS,
    delivery,
  }
}

const cancelDelivery = (history, deliveryId) => {
  return {
    type: CANCEL_DELIVERY,
    payload: {history, deliveryId},
  }
}
const cancelDeliverySuccess = () => {
  return {type: CANCEL_DELIVERY_SUCCESS}
}

const updateDelivery = (payload) => {
  return {type: UPDATE_DELIVERY, payload}
}

const setOrders = (payload) => {
  return {type: SET_ORDERS, payload}
}

// TODO: Move orders actions to their own orders actions file
const getOrder = (payload) => {
  return {type: GET_ORDER, payload}
}
const getOrderSuccess = (payload) => {
  return {type: GET_ORDER_SUCCESS, payload}
}

const addOrder = (payload) => {
  return {type: ADD_ORDER, payload}
}

const addOrderSuccess = (payload) => {
  return {type: ADD_ORDER_SUCCESS, payload}
}

const updateOrder = (payload) => {
  return {type: UPDATE_ORDER, payload}
}

const updateOrderSuccess = (payload) => {
  return {type: UPDATE_ORDER_SUCCESS, payload}
}

const deleteOrder = (payload) => {
  return {type: DELETE_ORDER, payload}
}

export {
  getDeliveries,
  getDeliveriesSuccess,
  getDeliveriesFail,
  getDelivery,
  getDeliverySuccess,
  clearCurrentDelivery,
  addDelivery,
  addDeliverySuccess,
  cancelDelivery,
  cancelDeliverySuccess,
  updateDelivery,
  setOrders,
  getOrder,
  getOrderSuccess,
  addOrder,
  addOrderSuccess,
  updateOrder,
  updateOrderSuccess,
  deleteOrder,
}
