import {put, takeLatest, select} from 'redux-saga/effects'

import {CREATE_PHARMACY, START_LOADING, STOP_LOADING} from '../actions/types'
import {showError} from '../actions'

import {API_STORE_ENDPOINT} from '../../constants'
import {request} from '../../utils/api'
import {getToken} from '../utils'

export function* createPharmacy({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'POST',
    url: API_STORE_ENDPOINT,
    token,
    body: payload,
    headers: {
      'Content-Type': 'application/json',
    }
  }
  try {
    yield put({type: START_LOADING})
    const store = yield request(reqOpt)
    yield put({type: STOP_LOADING})
    alert(`Successfully created store "${store.storeNumber}"`)
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* watchAdmin() {
  yield takeLatest(CREATE_PHARMACY, createPharmacy)
}

export default watchAdmin
