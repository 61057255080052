import React from 'react'
import styled from 'styled-components'
import get from 'lodash.get'

import {withStyles, makeStyles, useTheme} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'

import {CustomIconButton} from '../CustomIconButton'

import {isDateProp, formatDate, generalFormatDate} from '../../utils/dates'
import {getOrderStatusStep, getPaymentStatus, formatName} from '../../utils/common'
import {DeleteIcon, EditIcon} from '../Icons'
import {OrderDetailList} from '../OrderDetailList'
import OrderStatus from '../OrderStatus'
import StatusIcon from '../StatusIcon'

import TableHeadCell from './TableHeadCell'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.colors.background,
    color: theme.colors.label,
    border: 'none',
    height: '4.2rem',
    paddingTop: 0,
    paddingBottom: 0,
  },
  body: {
    backgroundColor: theme.colors.secondaryBackground,
    fontSize: 14,
    verticalAlign: 'middle',
    '&:not(first-child)': {
      borderWidth: '1px 0',
      borderColor: theme.colors.stroke,
      borderStyle: 'solid',
    },
    '&:first-child': {
      borderWidth: '1px 0 1px 1px',
      borderRadius: '5px 0 0 5px',
      borderColor: theme.colors.stroke,
      borderStyle: 'solid',
    },
    '&:last-child': {
      borderWidth: '1px 1px 1px 0',
      borderRadius: '0 5px 5px 0',
      borderColor: theme.colors.stroke,
      borderStyle: 'solid',
    },
  },
}))(TableCell)

const Container = styled.div`
  margin-top: 2.3rem;
  width: 100%;
`

const ActionsContainer = styled.div`
  display: flex;
`

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 4px',
  },
  tableContainer: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableHead: {
    color: theme.colors.label,
    border: 'none',
    height: '4.7rem',
  },
  row: {
    margin: '0 15px',
    verticalAlign: 'top',
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: `0px 4px 21px ${theme.card.shadow}`,
    borderRadius: '5px',
    padding: '15px 48px',
    marginTop: '28px',
  },
  actionIconLeft: {
    fill: `${theme.colors.iconfill}`,
    margin: ' 0 6px 0 0',
  },
  actionCell: {
    display: 'flex',
    '& svg': {
      marginRight: 5,
    },
  },
}))

const getMultiplePropCell = (multiKey, index, borderColor, dates, row) => {
  const key = Array.isArray(multiKey) ? multiKey[0] : multiKey

  switch (key) {
    case 'detail':
      return (
        <StyledTableCell
          key={`prop-${index}-${key}`}
          align="left"
          style={{borderColor}}>
          <OrderDetailList detail={row[key]} />
        </StyledTableCell>
      )
    case 'customerName':
        return (
          <StyledTableCell
          key={`prop-${index}-${key}`}
          align="left"
          style={{borderColor}}>
          {formatName(row[key])}
        </StyledTableCell>
        )
    case 'orderStatus':
      return (
        <StyledTableCell
          key={`prop-${index}-${key}`}
          align="left"
          style={{borderColor}}>
          <OrderStatus order={row} />
        </StyledTableCell>
      )
    case 'deliveryStatus':
      return (
        <StyledTableCell
          key={`prop-${index}-${key}`}
          align="left"
          style={{borderColor}}>
          <StatusIcon
            statusState={STATUS_THEME_MAP_DELIVERY[row[key]].statusTheme}
          />
          <span>{STATUS_THEME_MAP_DELIVERY[row[key]].label}</span>
        </StyledTableCell>
      )
    case 'paymentType':
      return (
        <StyledTableCell
          key={`prop-${index}-${key}`}
          align="left"
          style={{borderColor}}>
          <span>{row[key] === 'CARD' ? getPaymentStatus(row) : row[key]}</span>
        </StyledTableCell>
      )
    default:
      return (
        <StyledTableCell
          key={`prop-${index}-${key}`}
          align="left"
          style={{borderColor}}>
          {isDateProp(dates, key)
            ? formatDate(generalFormatDate(row[key]))
            : row[key]}
        </StyledTableCell>
      )
  }
}

const STATUS_THEME_MAP_DELIVERY = {
  DRAFT: {label: 'Draft', statusTheme: 'pending'},
  DROPOFF_PENDING: {label: 'Picked Up', statusTheme: 'pending'},
  DROPOFF_STARTED: {label: 'Picked Up', statusTheme: 'pending'},
  DROPOFF_SUCCESS: {label: 'Delivered Successfully', statusTheme: 'success'},
  DROPOFF_FAILURE: {label: 'Not Delivered', statusTheme: 'error'},
  PAYMENT_FAILURE: {label: 'Delivered Successfully', statusTheme: 'success'},
  PAYMENT_SUCCESS: {label: 'Delivered Successfully', statusTheme: 'success'},
  RETURNED_TO_PHARMACY: {label: 'Returned to Pharmacy', statusTheme: 'pending'},

  PICKUP_READY: {label: 'Ready for Pickup', statusTheme: 'pending'},
  PICKUP_ASSIGNED: {label: 'Ready for Pickup', statusTheme: 'pending'},
  PICKUP_STARTED: {label: 'Pickup in Progress', statusTheme: 'pending'},
  PICKUP_FAILURE: {label: 'Pickup Failed', statusTheme: 'error'},
  PICKUP_SUCCESS: {label: 'Delivery in Progress', statusTheme: 'pending'},
  DELIVERY_COMPLETE: {label: 'Delivery Complete', statusTheme: 'success'},
}

const STATUS_THEME_MAP_ORDER = {
  DRAFT: {label: 'Ready for Pickup', statusTheme: 'pending'},
  DROPOFF_PENDING: {label: 'Delivery in Progress', statusTheme: 'pending'},
  DROPOFF_STARTED: {label: 'Delivery in Progress', statusTheme: 'pending'},
  DROPOFF_SUCCESS: {label: 'Delivered Successfully', statusTheme: 'success'},
  DROPOFF_FAILURE: {label: 'Not Delivered', statusTheme: 'error'},
  PAYMENT_FAILURE: {label: 'Delivered Successfully', statusTheme: 'success'},
  PAYMENT_SUCCESS: {label: 'Delivered Successfully', statusTheme: 'success'},
  RETURNED_TO_PHARMACY: {label: 'Returned to Pharmacy', statusTheme: 'pending'},

  PICKUP_READY: {label: 'Ready for Pickup', statusTheme: 'pending'},
  PICKUP_ASSIGNED: {label: 'Ready for Pickup', statusTheme: 'pending'},
  PICKUP_STARTED: {label: 'Pickup in Progress', statusTheme: 'pending'},
  PICKUP_FAILURE: {label: 'Pickup Failed', statusTheme: 'error'},
  PICKUP_SUCCESS: {label: 'Delivery in Progress', statusTheme: 'pending'},
  DELIVERY_COMPLETE: {label: 'Delivery Complete', statusTheme: 'success'},
}

const CustomTable = ({
  headers = [],
  rows = [],
  children,
  properties = [],
  dates = [],
  keyProp,
  onClick = () => {},
  primaryAction = () => {},
  secondaryAction = () => {},
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isOrderEditable = (order) => {
    const orderStatus = get(order, 'orderStatus', '')
    const orderStep = getOrderStatusStep(orderStatus)
    return orderStep < getOrderStatusStep('DROPOFF_PENDING')
  }
  return (
    <Container>
      <TableContainer className={classes.tableContainer}>
        <Table
          id="table"
          stickyHeader
          className={classes.table}
          aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headers.map((header) => (
                <StyledTableCell align="left" key={header.label}>
                  <TableHeadCell header={header}>
                    {header.label}
                  </TableHeadCell>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!rows.length &&
              rows.map((row, rowIndex) => {
                const borderColor =
                  row.outstandingAmount > 0
                    ? theme.colors.error
                    : theme.colors.stroke
                const isEditable = isOrderEditable(row)
                return (
                  <TableRow
                    className={classes.row}
                    key={`${row}-${rowIndex}`}
                    rowid={row[keyProp]}
                    onClick={(e) => {
                      onClick(e.currentTarget.getAttribute('rowid'))
                    }}>
                    {properties.map((key, index) => {
                      return key === 'actions' ? (
                        <StyledTableCell
                          key={`prop-${index}-${key}`}
                          align="left"
                          style={{borderColor}}>
                          {isEditable ? (
                            <ActionsContainer>
                              <CustomIconButton
                                id={`edit-${rowIndex}-button`}
                                rowid={row[keyProp]}
                                onClick={(e) => {
                                  primaryAction(
                                    e.currentTarget.getAttribute('rowid'),
                                  )
                                }}>
                                <EditIcon className={classes.actionIconLeft} />
                              </CustomIconButton>
                              <CustomIconButton
                                id={`delete-${rowIndex}-button`}
                                rowid={row[keyProp]}
                                onClick={(e) => {
                                  secondaryAction(
                                    e.currentTarget.getAttribute('rowid'),
                                  )
                                }}>
                                <DeleteIcon />
                              </CustomIconButton>
                            </ActionsContainer>
                          ) : (
                            ''
                          )}
                        </StyledTableCell>
                      ) : (
                        getMultiplePropCell(key, index, borderColor, dates, row)
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {children ? (
        <Card className={classes.cardContainer}>{children}</Card>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default CustomTable
