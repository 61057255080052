import {put, takeEvery, select, throttle} from 'redux-saga/effects'
import get from 'lodash.get'

import {getToken} from '../utils'
import {request} from '../../utils/api'
import {apiFormatDate} from '../../utils/dates'

import {
  ADD_DELIVERY,
  GET_DELIVERIES,
  GET_DELIVERY,
  CANCEL_DELIVERY,
  UPDATE_DELIVERY,
  START_LOADING,
  STOP_LOADING,
} from '../actions/types'
import {
  addDeliverySuccess,
  getDeliveriesSuccess,
  getDeliveriesFail,
  getDeliverySuccess,
  cancelDeliverySuccess,
  showError,
} from '../actions'

import {API_DELIVERIES_ENDPOINT, API_THROTTLE_TIME_MS} from '../../constants'

export function* addDelivery({payload: {history}}) {
  const body = {deliveryDate: apiFormatDate()}
  const token = yield select(getToken)
  const reqOpt = {
    method: 'POST',
    url: API_DELIVERIES_ENDPOINT,
    token,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const delivery = yield request(reqOpt)
    yield put(addDeliverySuccess(delivery))
    yield put({type: STOP_LOADING})
    history.push(`/dashboard/delivery/${get(delivery, 'deliveryId', '')}`)
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* getDeliveries({payload: {dates}}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: API_DELIVERIES_ENDPOINT,
    token,
    filters: {...dates, limit: 20},
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const deliveries = yield request(reqOpt)

    yield put(getDeliveriesSuccess(deliveries))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(getDeliveriesFail(error))
    yield put(showError(error))
  }
}

export function* getDelivery({payload: {deliveryId}}) {
  const token = yield select(getToken)

  const reqOpt = {
    method: 'GET',
    url: `${API_DELIVERIES_ENDPOINT}/${deliveryId}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const delivery = yield request(reqOpt)

    yield put(getDeliverySuccess(delivery))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* cancelDelivery({payload: {history, deliveryId}}) {
  const token = yield select(getToken)

  const reqOpt = {
    method: 'DELETE',
    url: `${API_DELIVERIES_ENDPOINT}/${deliveryId}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield request(reqOpt)
    yield put(cancelDeliverySuccess())
    history.push(`/dashboard/home`)
  } catch (error) {
    yield put(showError(error))
  }
}

export function* updateDelivery({payload: {delivery, deliveryId, successCb}}) {
  const body = {
    ...delivery,
    deliveryDate: apiFormatDate(delivery.deliveryDate),
  }
  const token = yield select(getToken)
  const reqOpt = {
    method: 'PUT',
    url: `${API_DELIVERIES_ENDPOINT}/${deliveryId}`,
    token,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})

    yield request(reqOpt)

    if (successCb) {
      successCb()
    }

    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put(showError(error))
    yield put({type: STOP_LOADING})
  }
}

export function* watchDeliveries() {
  yield takeEvery(ADD_DELIVERY, addDelivery)
  yield throttle(API_THROTTLE_TIME_MS, GET_DELIVERIES, getDeliveries)
  yield throttle(API_THROTTLE_TIME_MS, GET_DELIVERY, getDelivery)
  yield takeEvery(CANCEL_DELIVERY, cancelDelivery)
  yield takeEvery(UPDATE_DELIVERY, updateDelivery)
}

export default watchDeliveries
