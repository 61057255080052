export const API_ENDPOINT =
  process.env.REACT_APP_PROXY === 'true'
    ? 'http://localhost:8010/proxy'
    : process.env.REACT_APP_API_URL

export const API_DELIVERIES_ENDPOINT = `${API_ENDPOINT}/deliveries`
export const API_ORDERS_ENDPOINT = `${API_ENDPOINT}/orders`
export const API_STORE_ENDPOINT = `${API_ENDPOINT}/store`
export const API_LABELS_ENDPOINT = `${API_ENDPOINT}/labelscan`
export const API_EXPORTS_ENDPOINT = `${API_ENDPOINT}/export/deliveries`
export const API_PAYMENT_ENDPOINT = `${API_ENDPOINT}/payment`

export const API_THROTTLE_TIME_MS = 2000
