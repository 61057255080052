import React from 'react'
import styled from 'styled-components'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const Price = styled.div`
  color: ${({theme}) => theme.colors.textSecondary};
`

const PrescriptionDetailContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`
const Prescription = ({txNumber, price, ...rest}) => {
  return (
    <PrescriptionDetailContainer {...rest}>
      {txNumber}
      <Price>${price}</Price>
    </PrescriptionDetailContainer>
  )
}

const CollapsablePrescription = styled(Prescription)`
  display: none;

  details[open] & {
    display: block;
  }
`

const Text = styled.div`
  color: ${({theme}) => theme.colors.primaryLight};
  display: flex;
  align-items: center;
`

const Number = styled.div`
  display: inline-block;
  text-align: center;
  background-color: ${({theme}) => theme.colors.iconBackground};
  border-radius: 6px;
  padding: 2px;
  font-size: 1.2rem;
  letter-spacing: 0.4px;
  line-height: 1.8rem;
  margin-right: 7px;
  padding: 0 0.5rem;
`

const Arrow = styled(ArrowForwardIosIcon)`
  width: 1rem;
  height: 1rem;
  margin-left: 4px;

  details[open] & {
    transform: rotate(270deg);
  }
`

const TextOpen = styled.span`
  display: none;

  details[open] & {
    display: inline;
  }
`

const TextClosed = styled.span`
  details[open] & {
    display: none;
  }
`
const Toggle = ({length}) => {
  return (
    <Text>
      <Number>{length}</Number>
      <TextClosed>More</TextClosed>
      <TextOpen>Less</TextOpen>
      <Arrow />
    </Text>
  )
}

const Summary = styled.summary`
  list-style: none;
  outline: none;

  &::-webkit-details-marker {
    display: none;
  }
`
const ExpandableList = ({children}) => {
  return (
    <details>
      <Summary>{children}</Summary>
    </details>
  )
}

const OrderDetailList = ({detail = []}) => {
  const formattedDetail = detail.map(({barcode = '', price}) => ({
    txNumber: barcode.slice(0, 8),
    price,
  }))

  const isExpandableList = formattedDetail.length > 3

  const visiblePrecriptions = formattedDetail.slice(0, 3)
  const hiddenPrescriptions = formattedDetail.slice(3)

  const seeMoreToggle = formattedDetail.length > 3 && (
    <Toggle length={detail.length - 3} />
  )

  const expandableList = (
    <ExpandableList>
      {visiblePrecriptions.map(({txNumber, price, i}) => (
        <Prescription
          key={`${txNumber}-${i}`}
          txNumber={txNumber}
          price={price}
        />
      ))}
      {hiddenPrescriptions.map(({txNumber, price, i}) => (
        <CollapsablePrescription
          key={`${txNumber}-${i}`}
          txNumber={txNumber}
          price={price}
        />
      ))}
      {seeMoreToggle}
    </ExpandableList>
  )

  const staticList = visiblePrecriptions.map(({txNumber, price, i}) => (
    <Prescription key={`${txNumber}-${i}`} txNumber={txNumber} price={price} />
  ))

  return isExpandableList ? expandableList : staticList
}

export {OrderDetailList}
