import {GET_PAYMENT_SUCCESS, PAYMENT_METHOD_SUCCESS, CLEAR_PAYMENT_METHOD} from '../actions/types'

function paymentReducers(state = {}, action = {}) {
  switch (action.type) {
    case GET_PAYMENT_SUCCESS:
        return {
          ...state,
          details: action.payload.payment
        }
    case PAYMENT_METHOD_SUCCESS:
      return {...state, stripePaymentMethodId: action.payload}
    case CLEAR_PAYMENT_METHOD:
      return {
        ...state,
        details: {},
        stripePaymentMethodId: null
      }
    default:
      return state
  }
}

export default paymentReducers
