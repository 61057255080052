import React, {useMemo, useRef, useState, useEffect} from 'react'
import styled, {useTheme} from 'styled-components'
import get from 'lodash.get'
import {Controller} from 'react-hook-form'

import InputLabel from '@material-ui/core/InputLabel'

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from './FormElements'
import Error from '../../components/Error'
import TextField from '../../components/Textfield'
import './styles.css'

const Row = styled.div`
  display: flex;
  .cardNumber {
    flex-basis: 32rem;
  }

  .cardExpiry {
    flex-basis: 11rem;
  }

  .cardCVV {
    flex-basis: 8.5rem;
  }
  & > *:not(:last-child) {
    margin-right: 2rem;
  }
`
const useOptions = (theme) => {
  const options = useMemo(
    () => ({
      cardNumber: {
        showIcon: true,
      },
      stripe: {
        classes: {
          base: 'stripe-text-input',
        },
        styles: {
          base: {
            fontSize: '1em',
            lineHeight: '1.2em',
            fontFamily: 'sans-serif',
            color: theme.colors.textPrimary,
            letterSpacing: '0.025em',
            padding: '18.5px 14px !important',
            '::placeholder': {
              color: theme.colors.label,
            },
          },
          invalid: {
            color: theme.colors.error,
          },
        },
      },
    }),
    [theme.colors.error, theme.colors.label, theme.colors.textPrimary],
  )

  return options
}
const StyledLabel = styled(InputLabel)`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.label};
  line-height: 2rem;
`
const CardHolderName = styled.div`
  width: 32rem;
`
const PaymentFormFields = ({
  register,
  errors,
  getValues,
  elements,
  control,
  card,
  ...props
}) => {
  const [isPrefilled, setIsPrefilled] = useState()
  const theme = useTheme()
  const options = useOptions(theme)

  useEffect(() => {
    setIsPrefilled(!!get(card, 'last4'))
  }, [card])

  const cardNumber = isPrefilled ? `**** **** **** ${get(card, 'last4')}` : ''
  const shortYear = get(card, 'expYear', 0) % 100
  const expiry = isPrefilled ? `${get(card, 'expMonth')} / ${shortYear}` : ''
  const cvc = isPrefilled ? '***' : ''

  function handleChange() {
    // prefilled credit card form gets wiped out on first change
    if (isPrefilled) {
      control.setValue('cardHolderName', '')
      control.setValue('cardNumber', '')
      control.setValue('cardExpiry', '')
      control.setValue('cardCvc', '')

      setIsPrefilled(false)
    }
  }

  return (
    <>
      <CardHolderName>
        <Controller
          name="cardHolderName"
          id="cardholder-name"
          control={control}
          render={({value, onChange}) => (
            <TextField
              type="text"
              label="Cardholder Name"
              value={value}
              onChange={e => {onChange(e); handleChange();}}
              error={!!errors.cardHolderName}
              helperText={get(errors, 'cardHolderName.message', '')}
              FormHelperTextProps={{
                component: (props) => (
                  <Error name="cardHolderName" {...props} />
                ),
              }}
            />
          )}
        />
      </CardHolderName>

      <Row id="stripe-form">
        <div className="cardNumber">
          <StyledLabel>
            Card number
            <Controller
              name="cardNumber"
              id="cardnumber"
              control={control}
              defaultValue={cardNumber}
              render={({value, onChange}) => (
                <CardNumberElement
                  id="card-number-input"
                  options={{...options.stripe, ...options.cardNumber}}
                  value={value}
                  onChange={handleChange}
                />
              )}
            />
          </StyledLabel>
        </div>
        <div className="cardExpiry">
          <StyledLabel>
            Expiration date
            <Controller
              name="cardExpiry"
              id="cardExpiry"
              control={control}
              defaultValue={expiry}
              render={({value, onChange}) => (
                <CardExpiryElement
                  options={options.stripe}
                  value={value}
                  onChange={handleChange}
                />
              )}
            />
          </StyledLabel>
        </div>
        <div className="cardCVV">
          <StyledLabel>
            CVV
            <Controller
              name="cardCvc"
              id="cardCvc"
              control={control}
              defaultValue={cvc}
              render={({value, onChange}) => (
                <CardCvcElement
                  options={options.stripe}
                  value={value}
                  onChange={handleChange}
                />
              )}
              />
          </StyledLabel>
        </div>
      </Row>
    </>
  )
}

export default PaymentFormFields
