const DELIVERY_STATUS_MAPPER = {
  PICKUP_READY: {
    step: 1,
    label:
      'Orders have been entered, awaiting driver to pick them up.',
  },
  PICKUP_ASSIGNED: {
    step: 1,
    label:
      'Orders have been entered, awaiting driver to pick them up.',
  },
  PICKUP_STARTED: {
    step: 2,
    label: 'The driver is picking up and verifying the orders for the delivery.',
  },
  PICKUP_SUCCESS: {
    step: 3,
    label: 'The driver is delivering the orders to your customers.',
  },
  DELIVERY_COMPLETE: {
    step: 4,
    label: 'Driver has completed the delivery.',
  },
}
const ORDER_STATUS_MAPPER = {
  DRAFT: {
    step: 1,
  },
  DROPOFF_PENDING: {
    step: 2,
  },
  DROPOFF_STARTED: {
    step: 3,
  },
  DROPOFF_SUCCESS: {
    step: 4,
  },
  DROPOFF_FAILURE: {
    step: 5,
  },
  PAYMENT_SUCCESS: {
    step: 6,
  },
  PAYMENT_FAILURE: {
    step: 7,
  },
  RETURNED_TO_PHARMACY: {
    step: 8,
  },
}

const EMPTY_ACTION = () => {}

const API_ERRORS_MAPPER = {
  400: {
    message: 'Validation error',
    action: EMPTY_ACTION
  },
  401: {
    message: '',
    action: () => {
      window.history.back()
    },
  },
  404: {
    message: 'The resource was not found (Error Code: <ErrorCodeNumber>)',
    action: () => {
      window.history.back()
    },
  },
  409: {
    message: 'Conflict: Resource already exists (Error Code: 409)',
  },
  502: {
    message:
      'There was an issue submitting the order, please review your data. (Error Code: 502)',
    action: EMPTY_ACTION,
  },
  504: {
    message:
      'The delivery service is experiencing some issues. (Error Code: 502)',
    action: EMPTY_ACTION,
  },
  default: {
    message:
      'There was an issue with your request (Error Code: <ErrorCodeNumber>)',
    action: EMPTY_ACTION,
  },
}

export {DELIVERY_STATUS_MAPPER, ORDER_STATUS_MAPPER, API_ERRORS_MAPPER}
