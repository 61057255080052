import React from 'react'
import {Dialog} from '@material-ui/core'

const Modal = ({
  isOpen,
  handleClose,
  children,
  fullWidth = false,
  fullScreen = false,
  maxWidth = false,
  ...props
}) => {
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      {...props}>
      {children}
    </Dialog>
  )
}

export default Modal
