import {
  UPDATE_USER,
  LOGOUT_USER,
  SAVE_TOKEN,
  GET_STORE,
  GET_STORE_SUCCESS,
} from '../actions/types'

const updateUser = (user) => {
  return {
    type: UPDATE_USER,
    payload: user,
  }
}

const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  }
}
const saveToken = (token) => {
  return {
    type: SAVE_TOKEN,
    payload: token,
  }
}

const getStoreInfo = (token) => {
  return {
    type: GET_STORE,
    payload: token,
  }
}
const getStoreInfoSuccess = (storeInfo) => {
  return {type: GET_STORE_SUCCESS, storeInfo}
}
export {updateUser, logoutUser, saveToken, getStoreInfo, getStoreInfoSuccess}
