import {createStore, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from './reducers'
import rootSaga from './sagas'

const defaultState = {
  user: {
    isLoading: false,
    deliveries: [],
    userInfo: {},
    isAuthenticated: false,
    token: '',
  },
  deliveries: {history: [], newDeliveryData: {}},
  orders: undefined,
  app: {error: undefined, loading: false},
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'deliveries', 'orders'],
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()
const middleware = applyMiddleware(sagaMiddleware)
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  defaultState,
  composeEnhancers(middleware),
)
sagaMiddleware.run(rootSaga)

let persistor = persistStore(store)

export {store, persistor}
