import {combineReducers} from 'redux'

import user from './user'
import deliveries from './deliveries'
import payment from './payment'
import app from './app'

const appReducer = combineReducers({user, deliveries, payment, app})

export default appReducer
