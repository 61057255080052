import {
  GET_PAYMENT,
  GET_PAYMENT_SUCCESS,
  PAYMENT_METHOD_SUCCESS,
  CLEAR_PAYMENT_METHOD,
} from '../actions/types'

const getPayment = (payload) => {
  return {
    type: GET_PAYMENT,
    payload,
  }
}

const getPaymentSuccess = (payload) => {
  return {
    type: GET_PAYMENT_SUCCESS,
    payload,
  }
}

const paymentMethodSuccess = (payload) => {
  return {
    type: PAYMENT_METHOD_SUCCESS,
    payload,
  }
}

const clearPaymentMethod = () => {
  return {
    type: CLEAR_PAYMENT_METHOD,
  }
}

export {getPayment, getPaymentSuccess, paymentMethodSuccess, clearPaymentMethod}
