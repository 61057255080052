import {select, put, takeEvery} from 'redux-saga/effects'

import {
  GET_PAYMENT,
  START_LOADING,
  STOP_LOADING
} from '../actions/types'
import {getPaymentSuccess, showError} from '../actions'
import {getToken} from '../utils'
import {API_PAYMENT_ENDPOINT} from '../../constants'
import {request} from '../../utils/api'

export function* fetchPayment({payload: {orderId, stripePaymentIntentId}}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: `${API_PAYMENT_ENDPOINT}/${stripePaymentIntentId}`,
    token,
  }
  try {
    yield put({type: START_LOADING})
    const payment = yield request(reqOpt)
    yield put(getPaymentSuccess({payment}))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* watchPayments() {
  yield takeEvery(GET_PAYMENT, fetchPayment)
}

export default watchPayments
