import React from 'react'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'
import {connect} from 'react-redux'

import {makeStyles} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

import Portal from '../../components/Portal'
import {Camera} from '../../components/Camera'
import {startLoading, stopLoading, scanLabels} from '../../redux/actions'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  position: relative;
`

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

function ScanLabel({startLoading, stopLoading, isCameraOpen, scanLabels}) {
  const classes = useStyles()
  const history = useHistory()

  const captureImage = (blob) => {
    scanLabels(blob, history)
  }
  const skipCapture = () => {
    scanLabels(null, history)
    stopLoading()
  }

  return (
    <Container>
      <AppBar>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Align Label and Click to Capture
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={skipCapture}
            aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {isCameraOpen && (
        <Portal>
          <Camera
            style={{position: 'fixed', top: 0, zIndex: 1299}}
            onRequestDeviceCamera={startLoading}
            onAcquireDeviceCamera={stopLoading}
            onCapture={captureImage}
          />
        </Portal>
      )}
    </Container>
  )
}

const mapDispatchToProps = (dispatch) => ({
  scanLabels: (image, history) => dispatch(scanLabels(image, history)),
  startLoading: () => dispatch(startLoading()),
  stopLoading: () => dispatch(stopLoading()),
})

export default connect(null, mapDispatchToProps)(ScanLabel)
