import React, {useState} from 'react'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import {useAuth0} from '@auth0/auth0-react'

import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Avatar from '@material-ui/core/Avatar'

import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AccountCircle from '@material-ui/icons/AccountCircle'

import Logo from '../../components/Logo'

const useStyles = makeStyles((theme) => ({
  logoButton: {
    borderRadius: 0,
    padding: 0,
    margin: 0,
    height: '64px',
    boxSizing: 'initial',
  },
  userMenuContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  logoutIcon: {
    marginLeft: '30px',
    fill: theme.colors.iconfill,
  },
  userIcon: {
    width: '32px',
    height: '32px',
    marginRight: '8px',
    fill: theme.colors.iconBackground,
  },
  expandMenuIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: theme.colors.iconBackground,
    },
  },
  expandMenuIcon: {
    fill: theme.colors.iconfill,
  },
}))

const UserMenu = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  & > *:not(:last-child) {
    margin-right: 12px;
  }
`
const NavBarContainer = styled.div`
  ${({theme}) => `
    width: 100%;
    z-index: 1;
    background-color: ${theme.colors.background};
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    padding-right: 45px;
  `}
`

const Navbar = ({isAuthenticated = true, userInfo = {}}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const {logout} = useAuth0()
  const history = useHistory()

  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout({returnTo: window.location.origin})
    handleMenuClose()
  }
  const navigateToDashboard = () => {
    history.push('/dashboard')
  }

  return (
    <NavBarContainer>
      <IconButton
        onClick={navigateToDashboard}
        edge="start"
        className={classes.logoButton}
        color="inherit"
        aria-label="menu">
        <Logo />
      </IconButton>
      {isAuthenticated && (
        <UserMenu>
          {userInfo.imageUrl ? (
            <Avatar src={userInfo.imageUrl} className={classes.userIcon} />
          ) : (
            <AccountCircle className={classes.userIcon} />
          )}
          <Typography variant="body1">{userInfo.name}</Typography>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenuToggle}
            className={classes.expandMenuIconButton}
            color="inherit">
            {anchorEl ? (
              <ExpandLessIcon className={classes.expandMenuIcon} />
            ) : (
              <ExpandMoreIcon className={classes.expandMenuIcon} />
            )}
          </IconButton>
          <Menu
            id="menu-appbar"
            className={classes.userMenuContainer}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            transformOrigin={{vertical: -25, horizontal: 'right'}}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}>
            <MenuItem onClick={handleLogout}>
              <Typography>Log out</Typography>
              <ExitToAppIcon className={classes.logoutIcon} />
            </MenuItem>
          </Menu>
        </UserMenu>
      )}
    </NavBarContainer>
  )
}

const mapStateToProps = ({user = {}}) => ({
  isAuthenticated: user.isAuthenticated,
  userInfo: user.userInfo,
})

export default connect(mapStateToProps)(Navbar)
