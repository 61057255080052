import React from 'react'

import ConfirmationDialog from '../ConfirmationDialog'

const CancelDeliveryModal = ({handleClose, handleCancelDelivery}) => {
  const description =
    'Any orders you have entered will be deleted.'

  return (
    <ConfirmationDialog
      id="cancel-delivery-dialog"
      closeModal={handleClose}
      handleContinueAction={handleCancelDelivery}
      handleCancelAction={handleClose}
      description={description}
      cancelActionLabel="continue editing"
      continueActionLabel="cancel this delivery"
    />
  )
}

export default CancelDeliveryModal
