import React from 'react'

const CashPaymentContainer = () => (
  <p>
    The driver will be instructed to collect cash from the customer upon
    delivery. The driver will then be responsible for bringing the outstanding
    cash back to the pharmacy
  </p>
)

export default CashPaymentContainer
