import React from 'react'
import styled from 'styled-components'
import InputLabel from '@material-ui/core/InputLabel'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import {connect} from 'react-redux'

import DatePicker from '../../components/DatePicker'
import {UnstyledButton} from '../../components/Button'
import {getInitialStartAndEndDate} from '../../utils/dates'
import {downloadCsv} from '../../redux/actions'

const SaveIcon = styled(SaveAltIcon)`
  margin-right: 5px;
  fill: ${({theme}) => theme.colors.iconfill};
`
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  & > * {
    margin-right: 1.6rem;
  }
`

const CenterContainer = styled.div`
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const {sevenDaysInFuture, threeWeeksInPast} = getInitialStartAndEndDate()
const DateFilter = ({onChange = () => {}, downloadCsv}) => {
  const [date, setSelectedDate] = React.useState({
    startDate: threeWeeksInPast,
    endDate: sevenDaysInFuture,
  })
  const handleDateChange = (name) => (newdate) => {
    setSelectedDate({...date, [name]: newdate})
    onChange({...date, [name]: newdate})
  }
  const handleDownload = () => {
    downloadCsv(date)
  }

  return (
    <Container>
      <DatePicker
        label="Start Date"
        name="startDate"
        date={date['startDate']}
        value={date['startDate']}
        onChange={handleDateChange('startDate')}
        autoOk
      />
      <CenterContainer>
        <InputLabel>to</InputLabel>
      </CenterContainer>
      <DatePicker
        label="End Date"
        name="endDate"
        date={date['endDate']}
        onChange={handleDateChange('endDate')}
        value={date['endDate']}
        autoOk
      />
      <CenterContainer>
        <UnstyledButton type="button" onClick={handleDownload}>
          <SaveIcon />
          Export Deliveries
        </UnstyledButton>
      </CenterContainer>
    </Container>
  )
}

const mapDispatchToProps = (dispatch) => ({
  downloadCsv: ({startDate, endDate}) =>
    dispatch(downloadCsv({startDate, endDate})),
})

export default connect(null, mapDispatchToProps)(DateFilter)
