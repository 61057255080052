import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({theme}) => theme.colors.background};
  padding: 2.8rem 4.7rem;
`
const TopContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 10rem;
  justify-content: space-between;
`
const DeliveryDetails = styled.div`
  width: 100%;
  min-height: 8rem;
  display: flex;
  justify-content: start;
`
const LabelTextCombination = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 2rem;
`

export {Container, TopContainer, DeliveryDetails, LabelTextCombination}
