import React from 'react'
import styled from 'styled-components'

import Divider from '@material-ui/core/Divider'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {PrimaryButton, SecondaryButton} from '../Button'
import {DialogActions, DialogContent, DialogTitle} from '@material-ui/core'

const DialogContentTitle = styled(Typography)`
  ${({theme}) => `
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${theme.colors.textPrimary};
`}
`

const DialogContentText = styled(Typography)`
  ${({theme}) => `
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.1px;
  margin: 1rem 0;
  span {
    font-weight: 500;
  }
`}
`

const PaymentMethod = styled(Typography)`
  text-transform: capitalize;
`

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: 'center',
  },
  dialogActions: {
    justifyContent: 'space-between',
    padding: '2rem',
  },
  successButton: {
    flexGrow: 2,
    width: '23.7rem',
  },
  closeButton: {
    flexGrow: 1,
    width: '16rem',
    marginRight: '2rem',
    padding: '15px 0',
    boxSizing: 'border-box',
    borderRadius: '5px',
    justifyContent: 'center',
  },
}))

const ConfirmPrescriptionModal = ({
  handleClose,
  updatePrescription,
  handleSubmitPrescription,
  orderId,
  getValues,
}) => {
  const classes = useStyles()
  const values = getValues()
  const {
    paymentMethod = '',
    fullName = '',
    phoneNumber = '',
    shippingAddress = '',
    prescriptions = [],
  } = values

  const saveNewPrescription = () => {
    const {prescriptions} = values
    const prescriptionWithNumericPrices = prescriptions.map((prescription) => {
      prescription.price = prescription.price.toString().replace(/\$/, '')
      return prescription
    })
    const formValues = {...values, prescriptions: prescriptionWithNumericPrices}
    orderId
      ? updatePrescription(formValues)
      : handleSubmitPrescription(formValues)
  }

  const cancelConfirmPrescriptionModal = () => {
    handleClose()
  }

  const sumTotalPrice = (prescriptions) => {
    const totalPrice = prescriptions
      .filter(({isDeleted}) => !isDeleted)
      .reduce((acc, prescription) => {
        const parsedPrice = prescription.price.toString().replace(/\$/, '')
        return acc + parseFloat(parsedPrice)
      }, 0)
    return totalPrice.toFixed(2)
  }

  return (
    <div id="confirmation-dialog">
      <DialogTitle className={classes.dialogTitle}>
        Confirm Order Details
      </DialogTitle>
      <DialogContent>
        <DialogContentTitle>Customer Information</DialogContentTitle>
        <DialogContentText>
          {fullName}
          <br />
          {shippingAddress}
          <br />
          {phoneNumber}
          <br />
        </DialogContentText>
        <DialogContentText>
          <span>Patient Pays: </span>${sumTotalPrice(prescriptions)}
        </DialogContentText>
        <div>
          <span>TX:</span>
          {prescriptions
            .filter(({isDeleted}) => !isDeleted)
            .map(({barCodeNumber}, i) => {
              return (
                <DialogContentText key={`{${barCodeNumber}-${i}}`}>
                  {`${i+1})`} {barCodeNumber}
                </DialogContentText>
              )
            })}
        </div>
      </DialogContent>
      <Divider variant="middle" />
      <DialogContent>
        <DialogContentTitle>Payment</DialogContentTitle>
        <DialogContentText>
          <PaymentMethod>{paymentMethod}</PaymentMethod>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <SecondaryButton
          id="secondary-action-button"
          className={classes.closeButton}
          onClick={cancelConfirmPrescriptionModal}>
          Return to Edit
        </SecondaryButton>
        <PrimaryButton
          id="primary-action-button"
          type="button"
          className={classes.successButton}
          onClick={saveNewPrescription}>
          Confirm Order
        </PrimaryButton>
      </DialogActions>
    </div>
  )
}

export default ConfirmPrescriptionModal
