import React from 'react'
import styled from 'styled-components'

import Lottie from 'react-lottie'
import {Dialog} from '@material-ui/core'

import 'react-toastify/dist/ReactToastify.css'

import * as animationData from './animation.json'

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
  }
`
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: JSON.parse(JSON.stringify(animationData)).default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}
const Loading = ({open = true}) => (
  <StyledDialog
    open={open}
    style={{
      zIndex: 1301,
    }}>
    <Lottie
      style={{
        zIndex: 1301,
        height: '100px',
        width: '100px',
      }}
      options={defaultOptions}
      isStopped={false}
      isPaused={false}
    />
  </StyledDialog>
)

export default Loading
