import React from 'react'
import styled from 'styled-components'
import get from 'lodash.get'

import StatusIcon from '../StatusIcon'

const STATUS_THEME_MAP_ORDER = {
  DRAFT: {label: 'Ready for Pickup', statusState: 'pending'},
  DROPOFF_PENDING: {label: 'Delivery in Progress', statusState: 'pending'},
  DROPOFF_STARTED: {label: 'Delivery in Progress', statusState: 'pending'},
  DROPOFF_SUCCESS: {label: 'Delivered Successfully', statusState: 'success'},
  DROPOFF_FAILURE: {label: 'Not Delivered', statusState: 'error'},
  PAYMENT_FAILURE: {label: 'Delivered Successfully', statusState: 'success'},
  PAYMENT_SUCCESS: {label: 'Delivered Successfully', statusState: 'success'},
  RETURNED_TO_PHARMACY: {label: 'Returned to Pharmacy', statusState: 'pending'},

  PICKUP_READY: {label: 'Ready for Pickup', statusState: 'pending'},
  PICKUP_ASSIGNED: {label: 'Ready for Pickup', statusState: 'pending'},
  PICKUP_STARTED: {label: 'Pickup in Progress', statusState: 'pending'},
  PICKUP_FAILURE: {label: 'Pickup Failed', statusState: 'error'},
  PICKUP_SUCCESS: {label: 'Delivery in Progress', statusState: 'pending'},
  DELIVERY_COMPLETE: {label: 'Delivery Complete', statusState: 'success'},
}

function formatFailureReason (failureReason = '') {
  return failureReason.slice(0, 1) + failureReason.slice(1).replace(/_/g, ' ').toLowerCase()
}

const StatusIconContainer = styled.div`
  display: flex;
  align-items: center;
`

const OrderStatus = ({ order : {orderStatus, completionNote, failureReason}}) => {
  const { statusState } = get(STATUS_THEME_MAP_ORDER, orderStatus, {})
  const { label } = get(STATUS_THEME_MAP_ORDER, orderStatus, {})
  const failureMessage = failureReason && !/none/i.test(failureReason)
    ? `Failure Reason: ${formatFailureReason(failureReason)}`
    : ''
  const note = completionNote
    ? `Driver Note: ${completionNote}`
    : ''

  return (
    <>
      <StatusIconContainer>
        <StatusIcon statusState={statusState} />
        <span>{label}</span>
      </StatusIconContainer>
      {failureMessage && <div>{failureMessage}</div>}
      {note && <div>{note}</div>}
    </>
  )
}

export default OrderStatus
