import React from 'react'
import {useHistory} from 'react-router-dom'

import ConfirmationDialog from '../ConfirmationDialog'

const CancelPrescriptionModal = ({handleClose, redirectUrl}) => {
  const history = useHistory()
  const description = 'Any information you have edited will not be saved.'

  const handleCancelPrescription = () => {
    history.push(redirectUrl)
  }
  return (
    <ConfirmationDialog
      closeModal={handleClose}
      handleContinueAction={handleCancelPrescription}
      handleCancelAction={handleClose}
      description={description}
      cancelActionLabel="continue editing"
      continueActionLabel="Exit without saving"
    />
  )
}

export default CancelPrescriptionModal
