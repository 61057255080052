export const UPDATE_USER = 'UPDATE_USER'
export const ADD_DELIVERY = 'ADD_DELIVERY'
export const ADD_DELIVERY_SUCCESS = 'ADD_DELIVERY_SUCCESS'
export const ADD_DELIVERY_FAIL = 'ADD_DELIVERY_FAIL'
export const GET_DELIVERIES = 'GET_DELIVERIES'
export const GET_DELIVERIES_SUCCESS = 'GET_DELIVERIES_SUCCESS'
export const GET_DELIVERIES_FAIL = 'GET_DELIVERIES_FAIL'
export const GET_DELIVERY = 'GET_DELIVERY'
export const GET_DELIVERY_SUCCESS = 'GET_DELIVERY_SUCCESS'
export const GET_DELIVERY_FAILURE = 'GET_DELIVERY_FAILURE'
export const CLEAR_CURRENT_DELIVERY = 'CLEAR_CURRENT_DELIVERY'
export const CANCEL_DELIVERY = 'CANCEL_DELIVERY'
export const CANCEL_DELIVERY_SUCCESS = 'CANCEL_DELIVERY_SUCCESS'
export const UPDATE_DELIVERY = 'UPDATE_DELIVERY'

export const SET_ORDERS = 'SET_ORDERS'

export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const ADD_ORDER = 'ADD_ORDER'
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS'
export const ADD_ORDER_FAIL = 'ADD_ORDER_FAIL'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const DELETE_ORDER = 'DELETE_ORDER'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const GET_STORE = 'GET_STORE'
export const GET_STORE_SUCCESS = 'GET_STORE_SUCCES'

export const SCAN_LABEL = 'SCAN_LABEL'
export const SCAN_LABEL_SUCCESS = 'SCAN_LABEL_SUCCESS'
export const SCAN_LABEL_FAILURE = 'SCAN_LABEL_FAILURE'

export const DOWNLOAD_CSV = 'DOWNLOAD_CSV'

export const GET_PAYMENT = 'GET_PAYMENT'
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS'
export const PAYMENT_METHOD_SUCCESS = 'PAYMENT_METHOD_SUCCESS'
export const CLEAR_PAYMENT_METHOD = 'CLEAR_PAYMENT_METHOD'

export const CREATE_PHARMACY = 'CREATE_PHARMACY'

export const SHOW_ERROR = 'SHOW_ERROR'
export const SILENCE_ERROR = 'SILENCE_ERROR'

export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
