import React from 'react'

import PaymentFormFields from './PaymentFormFields'
import CashPayment from '../CashPayment'
import NonePayment from '../NonePayment'



const PaymentForm = ({ paymentMethod, paymentDetails, ...props }) => {
  if (paymentMethod === 'credit'){
    return (
      <PaymentFormFields
        card={paymentDetails}
        {...props}
        />
    )
  }
  else if (paymentMethod === 'cash'){
    return (
      <CashPayment/>
    )
  } else {
    return (
      <NonePayment/>
    )
  }
}

export default PaymentForm
