import React from 'react'
import {Route, Switch} from 'react-router-dom'
import styled from 'styled-components'

import ProtectedRoute from './components/ProtectedRoute'
import ScrollToTop from './components/ScrollToTop'
import Login from './containers/Login'
import Dashboard from './containers/Dashboard'
import Admin from './containers/Admin'

import './App.css'

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-items: center;
`

function App() {
  return (
    <AppContainer>
      <ScrollToTop />
      <Switch>
        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <Route exact path="/" component={Login} />
        <ProtectedRoute path="/admin" component={Admin} />
      </Switch>
    </AppContainer>
  )
}

export default App
