import React from 'react'
import get from 'lodash.get'
import TextField from '../../../components/Textfield'

// Workaround the fact that Stripe elements cannot be prefilled
const WithPrefillableInput = WrappedComponent => ({ options, value, onChange, ...props }) => {
  const className = get(options, 'stripe.classes.base', '')
  const style = get(options, 'stripe.styles.base', {})

  const prefilledElement = (
    <TextField
      className={className}
      style={style}
      onChange={onChange}
      value={value}
      {...props}
    />)

  const stripeElement = (
    <WrappedComponent options={options} {...props} />)

  return value ? prefilledElement : stripeElement
}

export default WithPrefillableInput