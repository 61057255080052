import {all} from 'redux-saga/effects'

import deliveries from './deliveries'
import orders from './orders'
import user from './users'
import labels from './labels'
import download from './download'
import payment from './payment'
import admin from './admin'

function* rootSaga() {
  yield all([deliveries(), orders(), user(), labels(), download(), payment(), admin()])
}

export default rootSaga
