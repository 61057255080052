import {apiFormatDate, isDate} from './dates'
import {API_ERRORS_MAPPER} from './constants'

const getHeaders = (method = 'GET', token = '', body = {}, headers = {}) => ({
  method: method,
  mode: 'cors',
  cache: 'no-store',
  credentials: 'same-origin',
  headers: {
    ...headers,
    Authorization: 'Bearer ' + token,
    accept: 'application/json',
  },
  body: method === 'POST' || method === 'PUT' ? body : undefined,
})

const getFiltering = (filters) =>
  filters
    ? Object.keys(filters).reduce((acc, key, index) => {
        const filter = isDate(filters[key])
          ? apiFormatDate(filters[key])
          : filters[key]
        return index === 0
          ? `${acc}${key}=${filter}`
          : `${acc}&${key}=${filter}`
      }, '?')
    : ''

function formatErrorMessage({title, detail}) {
  return [title, detail].filter(Boolean).join(': ')
}

export function* request({method, url, token, body, filters, headers}) {
  const callConfig = getHeaders(method, token, body, headers)
  const finalUrl = `${url}${getFiltering(filters)}`

  return yield fetch(finalUrl, callConfig)
    .then(async (response) => {
      const statusCode = response.status
      const [contentType] = response.headers.get('content-type').split(';').slice(0, 1)
      
      const { data, errors = [] } = contentType === 'application/json'
        ? await response.json()
        : { data: response.blob() }

      if (statusCode === 400) {
        const validationMessage = errors
          .filter(error => error.status === 400)
          .map(formatErrorMessage)
          .join('\n')
        throw {
          message: `${validationMessage} (Error Code: 400)`,
          code: statusCode,
        }
      } else if (statusCode > 300) {
        const { message } = API_ERRORS_MAPPER[statusCode] || API_ERRORS_MAPPER.default
        throw {
          message,
          code: statusCode,
        }
      }
  
      return data
    })
}
