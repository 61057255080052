import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {connect} from 'react-redux'
import get from 'lodash.get'
import orderBy from 'lodash.orderby'

import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'
import AddIcon from '@material-ui/icons/Add'

import {
  getDelivery,
  clearCurrentDelivery,
  cancelDelivery,
  updateDelivery,
  deleteOrder,
  setOrders,
} from '../../redux/actions'
import {generalFormatDate} from '../../utils/dates'
import {formatName} from '../../utils/common'

import DatePicker from '../../components/DatePicker'
import Table from '../../components/Table'
import {PrimaryButton, CancelButton} from '../../components/Button'
import PrescriptionTotalSection from '../../components/PrescriptionTotalSection'

import {
  Container,
  TopContainer,
  DeliveryDetails,
  LabelTextCombination,
} from './styles'

import {
  Modal,
  CancelDeliveryModal,
  DeleteOrderModal,
  ConfirmDeliveryModal,
  CameraModal,
} from '../../components/Modal'
import BackButton from '../../components/BackButton'

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    marginTop: '1.6rem',
    marginBottom: '1.2rem',
  },
  topContainer: {
    textAlign: 'center',
  },
  label: {
    fontWeight: 500,
  },
  addressText: {
    marginTop: '26px',
  },
  addOrderButton: {
    width: '21.6rem',
    margin: '1.5rem 0',
  },
  nextButton: {
    maxWidth: '14rem',
    margin: '2.8rem 0 2.8rem 0',
    alignSelf: 'flex-end',
  },
}))

const Delivery = ({
  deliveryDate = new Date(),
  rootPath,
  delivery = {},
  orders = [],
  clearCurrentDelivery,
  cancelDelivery,
  updateDeliveryDate,
  deleteOrder,
  setOrders,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const {deliveryId} = useParams()

  useEffect(() => {
    //TODO: this is causing max stack errors - resolve this ASAP
    const formatedDate = generalFormatDate(deliveryDate)
    setSelectedDate(formatedDate)
  }, [deliveryDate])

  const [date, setSelectedDate] = useState(new Date(deliveryDate))
  const [modal, setModal] = React.useState({
    isOpen: false,
    modalContent: '',
  })

  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const [orderIdSelected, setOrderIdSelected] = useState()

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate)
    updateDeliveryDate({delivery: {deliveryDate: newDate}, deliveryId})
  }
  const handleModalClose = () => {
    setModal({
      isOpen: false,
      modalContent: '',
    })
  }
  const confirmCancelIntent = (e) => {
    setModal({
      isOpen: true,
      modalContent: 'CancelDeliveryModal',
    })
  }

  const handleCancelDelivery = () => {
    history.push('/dashboard')
    cancelDelivery(history, deliveryId)
  }

  const confirmDelivery = () => {
    updateDeliveryDate({
      delivery: {deliveryStatus: 'PICKUP_READY', deliveryDate: date},
      deliveryId,
      successCb: setConfirmationModal,
    })
  }

  const setConfirmationModal = () => {
    setModal({
      isOpen: true,
      modalContent: 'ConfirmDeliveryModal',
    })
  }

  const setDeleteOrderModal = () => {
    setModal({
      isOpen: true,
      modalContent: 'DeleteOrderModal',
    })
  }

  const redirectToHome = () => {
    history.push(rootPath)
    clearCurrentDelivery()
  }

  const sortOrders = (property, direction) => {
    let getSortableValue = null
    if (property === 'customerName') {
      getSortableValue = order => {
        return formatName(order.customerName)
      }
    } else {
      getSortableValue = order => String(get(order, property, '')).toLowerCase()
    }
    const sorted = orderBy(orders, [getSortableValue], [direction])
    setOrders(sorted)
  }

  const containsOrders = () => !!orders.length

  // TODO: architecture review look at if switch case is the best way to toggle modal content
  const displayModal = (modalType) => {
    switch (modalType) {
      case 'ConfirmDeliveryModal':
        return (
          <ConfirmDeliveryModal
            confirmModalIntent={redirectToHome}
            date={date}
            location={delivery.storeName}
          />
        )
      case 'CancelDeliveryModal':
        return (
          <CancelDeliveryModal
            handleCancelDelivery={handleCancelDelivery}
            handleClose={handleModalClose}
          />
        )
      case 'DeleteOrderModal':
        return (
          <DeleteOrderModal
            primaryAction={() => {
              deleteOrder(orderIdSelected, history)
            }}
            secondaryAction={handleModalClose}
          />
        )
      default:
        break
    }
  }

  return (
    <>
      <Container>
        <TopContainer className={classes.topContainer}>
          <BackButton click={redirectToHome} />
          <Typography variant="h1" className={classes.header}>
            Start New Delivery
          </Typography>
          <CancelButton onClick={confirmCancelIntent} />
        </TopContainer>

        <DeliveryDetails>
          <DatePicker
            label="Select Delivery Date"
            name="deliveryDate"
            date={date}
            value={date}
            onChange={handleDateChange}
            autoOk
          />
          <LabelTextCombination>
            <FormLabel className={classes.label}>Pharmacy location</FormLabel>
            <Typography variant="body1" className={classes.addressText}>
              {delivery.storeName}
            </Typography>
          </LabelTextCombination>
        </DeliveryDetails>
        <Table
          rows={orders}
          keyProp="orderId"
          headers={[
            { label: 'Name', property: 'customerName', onClick: sortOrders },
            { label: 'Address' },
            { label: 'Phone', property: 'customerPhone', onClick: sortOrders },
            { label: 'TX Number / Price' },
            { label: 'Payment Type', property: 'paymentType', onClick: sortOrders },
            { label: 'Delivery Status' },
            { label: 'Actions' },
          ]}
          properties={[
            'customerName',
            'customerAddress',
            'customerPhone',
            'detail',
            'paymentType',
            'orderStatus',
            'actions',
          ]}
          primaryAction={(orderId) => {
            history.push(`${deliveryId}/add-new-prescription/${orderId}`)
          }}
          secondaryAction={(orderId) => {
            setOrderIdSelected(orderId)
            setDeleteOrderModal()
          }}>
          <PrimaryButton
            id="add-prescription-button"
            className={classes.addOrderButton}
            startIcon={<AddIcon />}
            onClick={() => setIsCameraOpen(true)}>
            Add an Order
          </PrimaryButton>
        </Table>
        {!!orders.length && (
          <PrescriptionTotalSection orders={orders} delivery={delivery} />
        )}
        <PrimaryButton
          id="schedule-delivery-button"
          type="button"
          disabled={!containsOrders()}
          className={classes.nextButton}
          onClick={confirmDelivery}>
          Schedule Delivery
        </PrimaryButton>
      </Container>
      <CameraModal
        isCameraOpen={isCameraOpen}
        setIsCameraOpen={setIsCameraOpen}
      />
      <Modal isOpen={modal.isOpen} handleClose={handleModalClose}>
        {displayModal(modal.modalContent)}
      </Modal>
    </>
  )
}
const mapStateToProps = ({user = {}, deliveries = []}) => {
  return {
    delivery: deliveries.selectedDelivery,
    deliveryDate: get(deliveries, 'selectedDelivery.deliveryDate'),
    orders: get(deliveries, 'selectedDelivery.orders', []),
    token: user.token,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDelivery: (deliveryId) => dispatch(getDelivery(deliveryId)),
  clearCurrentDelivery: () => dispatch(clearCurrentDelivery()),
  cancelDelivery: (history, deliveryId) =>
    dispatch(cancelDelivery(history, deliveryId)),
  updateDeliveryDate: (payload) => dispatch(updateDelivery(payload)),
  deleteOrder: (orderId, history) => dispatch(deleteOrder({orderId, history})),
  setOrders: (orders) => dispatch(setOrders(orders))
})

export default connect(mapStateToProps, mapDispatchToProps)(Delivery)
