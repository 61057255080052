import React from 'react'
import {withStyles, makeStyles} from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.colors.label,
    fontWeight: 500,
    fontSize: '1.4rem',
  },
}))

const StyledCheckBox = withStyles((theme) => ({
  root: {
    color: theme.colors.stroke,
    '&$checked': {
      color: theme.colors.primary,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />)

const CheckBox = ({label, id, ...props}) => {
  const classes = useStyles()

  return (
    <FormControlLabel
      classes={{label: classes.label}}
      control={<StyledCheckBox id={id} {...props} />}
      label={label}
    />
  )
}
export default CheckBox
