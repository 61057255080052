import {put, select, throttle, call} from 'redux-saga/effects'
import {saveAs} from 'file-saver'

import {API_EXPORTS_ENDPOINT, API_THROTTLE_TIME_MS} from '../../constants'
import {getToken} from '../utils'
import {request} from '../../utils/api'
import {apiFormatDate} from '../../utils/dates'

import {DOWNLOAD_CSV} from '../actions/types'
import {showError, startLoading, stopLoading} from '../actions'

export function* getCSVDownloadData({payload: {startDate, endDate}}) {
  const token = yield select(getToken)

  const reqOpt = {
    method: 'GET',
    url: API_EXPORTS_ENDPOINT,
    token,
    filters: {
      startDate,
      endDate,
    },
  }

  yield put(startLoading())

  try {
    const csvData = yield request(reqOpt)
    const fileName = `scriptrunner ${apiFormatDate(startDate)} ${apiFormatDate(endDate)}.csv`
    yield call([this, saveAs], csvData, fileName)
  } catch (error) {
    yield put(showError(error))
  }

  yield put(stopLoading())
}

export function* watchDownloadCSV() {
  yield throttle(API_THROTTLE_TIME_MS, DOWNLOAD_CSV, getCSVDownloadData)
}

export default watchDownloadCSV
