import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import get from 'lodash.get'
import orderBy from 'lodash.orderby'

import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'

import PrescriptionTotalSection from '../../components/PrescriptionTotalSection'
import Table from '../../components/Table'
import {UnstyledButton} from '../../components/Button'
import Divider from '../../components/FormDivider'
import {
  Modal,
  CancelDeliveryModal,
  DeleteOrderModal,
  CameraModal,
} from '../../components/Modal'

import DeliveryStatusTracker from '../DeliveryStatusTracker'
import DriverStatus from '../DriverStatus'
import {
  Container,
  CancelDeliveryButton,
  HeaderTypography,
  HeaderContainer,
  TopContainer,
} from './styles'

import {deleteOrder, cancelDelivery, setOrders} from '../../redux/actions'
import BackButton from '../../components/BackButton'
import {getDeliveryStatusStep, getOrderStatusStep,formatName} from '../../utils/common'

function getTableProperties(isDeliveryEditable) {
  const defaultProperties = [
    'customerName',
    'customerAddress',
    'customerPhone',
    'detail',
    'paymentType',
    'orderStatus',
  ]

  return isDeliveryEditable ? [...defaultProperties, 'actions'] : defaultProperties
}

const DeliveryStatus = ({
  rootPath,
  orders = [],
  delivery,
  delivery: {driver, deliveryId, deliveryStatus} = {},
  deleteOrder,
  cancelDelivery,
  setOrders,
}) => {
  const history = useHistory()
  const [modal, setModal] = React.useState({
    isOpen: false,
    modalContent: '',
  })

  const [orderIdSelected, setOrderIdSelected] = useState()
  // Scan camera state
  const [isCameraOpen, setIsCameraOpen] = useState(false)

  const redirectToHome = () => {
    history.push(rootPath)
  }

  const deliveryStep = getDeliveryStatusStep(deliveryStatus)
  const isDeliveryEditable =
    deliveryStep < getDeliveryStatusStep('PICKUP_SUCCESS')

  const handleCancelDelivery = () => {
    history.push('/dashboard')
    cancelDelivery(history, deliveryId)
  }
  const handleModalClose = () => {
    setModal({
      isOpen: false,
      modalContent: '',
    })
  }
  const confirmCancelIntent = (e) => {
    setModal({
      isOpen: true,
      modalContent: 'CancelDeliveryModal',
    })
  }

  const setDeleteOrderModal = () => {
    setModal({
      isOpen: true,
      modalContent: 'DeleteOrderModal',
    })
  }

  const displayModal = (modalType) => {
    switch (modalType) {
      case 'CancelDeliveryModal':
        return (
          <CancelDeliveryModal
            handleCancelDelivery={handleCancelDelivery}
            handleClose={handleModalClose}
          />
        )
      case 'DeleteOrderModal':
        return (
          <DeleteOrderModal
            primaryAction={() => {
              deleteOrder(orderIdSelected, history)
            }}
            secondaryAction={handleModalClose}
          />
        )
      default:
        break
    }
  }

  const sortOrders = (property, direction) => {
    let getSortableValue = null
    if(property === 'orderStatus'){
      getSortableValue = order => getOrderStatusStep(get(order, 'orderStatus'))
    } else if(property === 'customerName') {
      getSortableValue = order => {
        return formatName(order.customerName)
      }
    } else {
      getSortableValue = order => String(get(order, property, '')).toLowerCase()
    }
    const sorted = orderBy(orders, [getSortableValue], [direction])
    setOrders(sorted)
  }

  function getTableHeaders(isDeliveryEditable) {
    const defaultHeaders = [
      { label: 'Name', property: 'customerName', onClick: sortOrders },
      { label: 'Address' },
      { label: 'Phone', property: 'customerPhone', onClick: sortOrders },
      { label: 'TX Number / Price' },
      { label: 'Payment Type', property: 'paymentType', onClick: sortOrders },
      { label: 'Delivery Status', property: 'orderStatus', onClick: sortOrders },
    ]
  
    const actionsHeader = { label: 'Actions' }

    return isDeliveryEditable
      ? [...defaultHeaders, actionsHeader]
      : defaultHeaders
  }

  return (
    <>
      <Container>
        <HeaderContainer>
          <BackButton click={redirectToHome} />
          <HeaderTypography variant="h1">{`Delivery #${deliveryId}`}</HeaderTypography>
          {isDeliveryEditable && (
            <CancelDeliveryButton onClick={confirmCancelIntent}>
              Cancel Delivery
            </CancelDeliveryButton>
          )}
        </HeaderContainer>
        <DeliveryStatusTracker delivery={delivery} />
        <DriverStatus driver={driver} />
        <Divider />
        <TopContainer>
          <Typography variant="h1">
            {orders.length === 1
              ? `${orders.length} Order`
              : ` ${orders.length} Orders`}
          </Typography>
          {isDeliveryEditable && (
            <UnstyledButton onClick={() => setIsCameraOpen(true)}>
              <AddIcon />
              Add an Order
            </UnstyledButton>
          )}
        </TopContainer>
        <Table
          rows={orders}
          keyProp="orderId"
          headers={getTableHeaders(isDeliveryEditable)}
          properties={getTableProperties(isDeliveryEditable)}
          primaryAction={(orderId) => {
            history.push(`${deliveryId}/add-new-prescription/${orderId}`)
          }}
          secondaryAction={(orderId) => {
            setOrderIdSelected(orderId)
            setDeleteOrderModal()
          }}
        />
        {!!orders.length && (
          <PrescriptionTotalSection orders={orders} delivery={delivery} />
        )}
      </Container>
      <Modal isOpen={modal.isOpen} handleClose={handleModalClose}>
        {displayModal(modal.modalContent)}
      </Modal>
      <CameraModal
        isCameraOpen={isCameraOpen}
        setIsCameraOpen={setIsCameraOpen}
      />
    </>
  )
}
const mapStateToProps = ({user = {}, deliveries = []}) => {
  return {
    delivery: deliveries.selectedDelivery,
    orders: get(deliveries, 'selectedDelivery.orders', []),
  }
}
const mapDispatchToProps = (dispatch) => ({
  cancelDelivery: (history, deliveryId) =>
    dispatch(cancelDelivery(history, deliveryId)),
  deleteOrder: (orderId, history) => dispatch(deleteOrder({orderId, history})),
  setOrders: (orders) => dispatch(setOrders(orders)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryStatus)
