import {
  GET_DELIVERIES_SUCCESS,
  GET_DELIVERY_SUCCESS,
  GET_DELIVERIES_FAIL,
  CLEAR_CURRENT_DELIVERY,
  ADD_DELIVERY_SUCCESS,
  CANCEL_DELIVERY_SUCCESS,
  SCAN_LABEL_SUCCESS,
  SET_ORDERS,
} from '../actions/types'

function deliveriesDataReducers(state = {}, action = {}) {
  switch (action.type) {
    case GET_DELIVERIES_SUCCESS:
      return {...state, userInfo: action.payload, history: action.deliveries}
    case GET_DELIVERIES_FAIL:
      return {...state, history: []}
    case GET_DELIVERY_SUCCESS:
      return {...state, selectedDelivery: action.delivery}
    case CLEAR_CURRENT_DELIVERY:
      return {...state, selectedDelivery: {}}
    case ADD_DELIVERY_SUCCESS:
      return {...state, selectedDelivery: action.delivery}
    case CANCEL_DELIVERY_SUCCESS:
      return {...state, selectedDelivery: {}}
    case SCAN_LABEL_SUCCESS:
      return {...state, newDeliveryData: action.payload}
    case SET_ORDERS:
      return {
        ...state,
        selectedDelivery: {
          ...state.selectedDelivery,
          orders: action.payload,
        }
      }
    default:
      return state
  }
}

export default deliveriesDataReducers
