import React from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {createPharmacy} from '../../redux/actions'
import Loading from '../../components/Loading'

const Form = styled.form`
  width: 100%;
  max-width: 500px;

  & label {
    display: grid;
    margin-top: 15px;
    font-size: large;
  }
  & input {
    font-size: large;
  }
`

const Admin = ({ loading, createPharmacy }) => {
  const onSubmit = e => {
    e.preventDefault()
    const formData = new FormData(e.target);
    const json = JSON.stringify(Object.fromEntries(formData))
    createPharmacy(json)
  }

  const loadingSpinner = (<Loading />)

  const form = (
    <Form onSubmit={onSubmit}>
      <label>
      Store Number
        <input type="text" required name="storeNumber"></input>
      </label>
      <label>
      Store Name
        <input type="text" required name="storeName"></input>
      </label>
      <label>
      Store Phone
        <input type="tel" required name="storePhone"></input>
      </label>
      <label>
      Store Address
        <input type="text" required name="storeAddress"></input>
      </label>
      <label>
      Stripe Account Id
        <input type="text" required name="stripeAccountId" defaultValue="acct_1HMH0DLciwgVgRK7"></input>
      </label>
      <br></br>
      <button>Create Store</button>
    </Form>
  )

  return loading ? loadingSpinner : form
}

const mapStateToProps = ({app: {loading}}) => {
  return {
    loading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  createPharmacy: (pharmacy) => dispatch(createPharmacy(pharmacy)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
