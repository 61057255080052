import {CREATE_PHARMACY} from './types'

const createPharmacy = (pharmacy) => {
  return {
    type: CREATE_PHARMACY,
    payload: pharmacy,
  }
}

export {createPharmacy}
