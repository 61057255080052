import React from 'react'
import styled from 'styled-components'
import {useAuth0} from '@auth0/auth0-react'

import {PrimaryButton} from '../../components/Button'

import Logo from '../../logo-login.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 32rem;
  margin: auto;

  & > * {
    margin-bottom: 5rem;
  }
`

const Login = () => {
  const {loginWithRedirect} = useAuth0()
  const login = () => {
    loginWithRedirect({display: 'touch'})
  }
  return (
    <Container className="login-container">
      <img src={Logo} alt="script runner logo" />
      <PrimaryButton onClick={login} className="login-button">
        Login
      </PrimaryButton>
    </Container>
  )
}
export default Login
