import styled from 'styled-components'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import MuiEditIcon from '@material-ui/icons/Edit'

const DeleteIcon = styled(DeleteForeverIcon)`
  height: 4rem;
  fill: ${({theme}) => theme.colors.iconfill};
`
const EditIcon = styled(MuiEditIcon)`
  height: 4rem;
  fill: ${({theme}) => theme.colors.iconfill};
`
const SaveIcon = styled(SaveAltIcon)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 5px;
  fill: ${({theme}) => theme.colors.iconfill};
`

const BackIcon = styled(ArrowBackIosIcon)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 5px;
  fill: ${({theme}) => theme.colors.iconfill};
`

export {BackIcon, DeleteIcon, EditIcon, SaveIcon}
