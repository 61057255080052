import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {connect} from 'react-redux'

import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import {getDeliveries, addDelivery, getStoreInfo} from '../../redux/actions'

import Table from '../../components/Table'
import NewDeliverySection from '../../components/NewDeliverySection'
import DateFilter from '../DateFilter'
import Loading from '../../components/Loading'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({theme}) => theme.colors.background};
  padding: 2.8rem 4.7rem;
`

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    marginTop: '1.6rem',
    marginBottom: '1.2rem',
  },
}))

const Home = ({
  getDeliveries,
  getFilteredDeliveries,
  getStoreInfo,
  newDelivery,
  deliveries = [],
  user = {},
  token = '',
  loading
}) => {
  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    token && getFilteredDeliveries()
    token && getStoreInfo(token)
  }, [token, user, getDeliveries, getStoreInfo])
  return (
    <>
      <Container id="delivery-container">
        <NewDeliverySection onClick={newDelivery(history)} />
        <Typography variant="h1" className={classes.header}>
          Delivery History
        </Typography>
        <DateFilter onChange={(dates) => getFilteredDeliveries(dates)} />
        <Table
          rows={deliveries}
          headers={[
            { label: 'Date' },
            { label: 'Delivery Number' },
            { label: '# of Orders' },
            { label: 'Driver' },
            { label: 'Status' },
            { label: 'Outstanding Cash' },
          ]}
          properties={[
            'deliveryDate',
            'deliveryId',
            'totalOrders',
            'driverName',
            'deliveryStatus',
            'cashOutstanding',
          ]}
          dates={['deliveryDate']}
          keyProp="deliveryId"
          onClick={(deliveryId) => {
            history.push(`/dashboard/delivery/${deliveryId}`)
          }}
        />
      </Container>
      <Loading open={loading} />
    </>
  )
}

const mapStateToProps = ({app = {}, user = {}, deliveries = []}) => ({
  deliveries: deliveries.history,
  user: user.userInfo,
  token: user.token,
  loading: app.loading
})

const mapDispatchToProps = (dispatch) => ({
  getStoreInfo: (token) => dispatch(getStoreInfo(token)),
  getDeliveries: () => dispatch(getDeliveries()),
  getFilteredDeliveries: (dates) => {
    dispatch(getDeliveries(dates))
  },
  newDelivery: (history) => () => dispatch(addDelivery(history)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
